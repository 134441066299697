import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import './App.css';

import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import FatGreenButton from './components/buttons/fat-green-button/FatGreenButton';
import { Context } from './context/Context';
import {
    getHaveSeenRenovationWarning,
    isSubscriber,
    returnAuthenticated
} from './context/getData';
import HeadersContainer from './components/headers/headers-container/HeadersContainer';
import { useState } from 'react';
import LoginOrSignupDialog from './components/dialogs/login-or-signup-dialog/LoginOrSignupDialog';
import AskForLoginDialog from './components/dialogs/ask-for-login-dialog/AskForLoginDialog';
import RenovationInfoDialog from './components/dialogs/renovation-info-dialog/RenovationInfoDialog';

const HomeContainer = styled.div`
    height: 100vh;
    @media (min-height: 1100px) and (min-width: 960px) {
        height: 75vh;
    }
`;

const BackgroundImage = styled.div`
    background-image: url('/images/header-history-small.jpg');
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 90vh;
    position: absolute;
    opacity: 0.6;
    z-index: 0;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        height: 100vh;
    }

    @media (min-width: 792px) {
        height: 100vh;
    }

    @media (min-height: 1100px) and (min-width: 960px) {
        height: 75vh;
    }
`;

const HomeContent = styled.div`
    position: relative;
    z-index: 1;
    min-height: 650px;
    width: 100%;
`;

const HomeText = styled.div`
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        top: 48%;
    }
`;

const MainText = styled(Typography)`
    font-size: 3rem;
    text-align: center;
    margin-bottom: 30px;
    font-weight: bold;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        font-size: 2.6rem;
        margin-bottom: 20px;
    }
`;

const Subtitle = styled(Typography)`
    font-size: 1.25rem;
    text-align: center;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        margin-bottom: 2rem;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: block;
        width: 80%;
    }
`;

function Home(props) {
    const [loginOrSignupDialog, setLoginOrSignupDialog] = useState(false);
    const [askForLoginDialog, setAskForLoginDialog] = useState(false);
    const [showRenovationInfoDialog, setShowRenovationInfoDialog] = useState(
        false
    );
    const context = useContext(Context);

    const onBookClick = () => {
        if (returnAuthenticated()) {
            props.history.push('/booking-kalender');
        } else {
            setAskForLoginDialog(true);
        }
    };

    const onMembershipClick = () => {
        if (returnAuthenticated()) {
            if (isSubscriber()) {
                // If the user is already subscriber, just take them to mypage.
                props.history.push('/min-side');
            } else {
                console.log('got to else');
                // If the user is not subscriber, we should send them to the checkout page, and add subscription in the local storage
                context.saveOrder({ subscription: true });
                props.history.push({
                    pathname: '/opsummering',
                    state: {
                        from: { path: '/', title: 'hjemmesiden' },
                        isOnlySignup: true
                    }
                });
            }
        } else {
            setLoginOrSignupDialog(true);
        }
    };

    const onRegisterClick = () => {
        props.history.push({
            pathname: '/oplysninger',
            state: { isOnlySignup: true }
        });
    };

    useEffect(() => {
        // Check if we have showed the warning about the renovation. If no, show the dialog
        if (!getHaveSeenRenovationWarning()) {
            setShowRenovationInfoDialog(true);
        }
    }, []);

    return (
        <HomeContainer className="home-container">
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.7 }}
            >
                <BackgroundImage />
                <HeadersContainer />

                <HomeContent>
                    <HomeText>
                        <MainText variant="h1">
                            Velkommen til Bülowsvej 10
                        </MainText>
                        <Subtitle variant="h2">
                            Vi har rum til dig og dine aktiviteter
                        </Subtitle>
                        <ButtonContainer>
                            <FatGreenButton
                                title="Reserver lokale"
                                margin="2rem 0.5rem 2rem 0.5rem"
                                minWidth="176px"
                                onButtonClick={onBookClick}
                                opacityChangeOnHover={true}
                                mobileMargin="1rem auto"
                                mobileWidth="100%"
                            />
                            {returnAuthenticated() ? (
                                <FatGreenButton
                                    title="Bliv medlem"
                                    margin="2rem 0.5rem 2rem 0.5rem"
                                    minWidth="176px"
                                    onButtonClick={onMembershipClick}
                                    opacityChangeOnHover={true}
                                    mobileMargin="1rem auto"
                                    mobileWidth="100%"
                                />
                            ) : (
                                <FatGreenButton
                                    title="Opret bruger"
                                    margin="2rem 0.5rem 2rem 0.5rem"
                                    minWidth="176px"
                                    onButtonClick={onRegisterClick}
                                    opacityChangeOnHover={true}
                                    mobileMargin="1rem auto"
                                    mobileWidth="100%"
                                />
                            )}
                        </ButtonContainer>
                    </HomeText>
                </HomeContent>
                {loginOrSignupDialog && (
                    <LoginOrSignupDialog
                        close={() => setLoginOrSignupDialog(false)}
                    />
                )}

                {askForLoginDialog && (
                    <AskForLoginDialog
                        close={() => setAskForLoginDialog(false)}
                    />
                )}

                {/* {showRenovationInfoDialog && (
                    <RenovationInfoDialog
                        close={() => setShowRenovationInfoDialog(false)}
                    />
                )} */}
            </motion.div>
        </HomeContainer>
    );
}

export default withRouter(Home);
